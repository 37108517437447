import * as React from "react"
import Layout from "../../components/layout"
import Hero from '../../components/hero'
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import AdBanner from '../../components/adbanner'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'
import BlockQuote from '../../components/blockquote'
import Bibliography from '../../components/bibliography.js'


const GroveQuote = {content: 'A closed lyrical piece for solo voice (exceptionally, for more than one), either independent or forming part of an opera or other large work.', quotee: 'The New Grove Dictionary of Opera'}
const Opera101Quote = {content: 'An aria in an opera is a set-piece song for a solo singer in which the character expresses an emotion or ideal that doesn’t necessarily drive the story forward. It is a formalized song, often highly structured and full of repetition, that is designed as much to display the virtues of the singer as to enhance the narrative.', quotee: 'The Opera 101'}

const bibliography = [
  {name: 'The New Grove Dictionary of Opera', writer: 'Stanley Sadie', url: 'https://amzn.to/2VhOve7'},
  {name: 'Opera A History', writer: 'Christopher Headington', url: 'https://amzn.to/3gZ3lOf'},
]

// markup
const AriasPage = () => {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Operatic Aria | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operaabc/arias" />
          <meta name="description" content="What's an aria? An explanation of operatic arias with descriptions and examples from the great composers" />
        </Helmet>
        <Hero title="The Operatic Aria" subtitle="The different forms, with a bit of history and lots of examples"/>
        <MainBodySection>

          <BlockQuote quote={GroveQuote}/>

          <p>Aria (sometimes anglicised as air and occasionally pluralized as arie) is a fairly all encompassing term that captures vast swathes of music across multiple genres including oratorios, cantatas and musicals. From the Baroque right through to the Romantic period (albeit to a steadily declining degree) operas were packed to the brim with arias. From the late Romantic period to today many operas (most even) have few to none.</p>

          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>No arias doesn’t mean no singing, quite the opposite in fact, most works from this more recent period were written as sung-through works. This means no talking or recitative at all.</p>

          <p>How are we to define an aria in the context of opera? In these terms an aria is perhaps easier to understand in the context of the function it fulfils rather than its dictionary definition. As you dive into opera and come across a vast range of aria types (some of which we’ll cover below) you’ll notice arias are often defined by their emotional content rather than their musical form. So here goes...</p>

          <BlockQuote quote={Opera101Quote}/>

          <p>This is a far from comprehensive definition but certainly for pre-Gluck music (roughly pre-1760) it covers most of our bases and despite Gluck’s innovations really covers us right through into early Verdi (the 1840s or so) and a bit beyond.</p>

        </MainBodySection>


        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>          

          <p>Prior to Verdi, operas were essentially a huge collection of arias with maybe a duet or quartet tossed in for good measure (up to 40 or 50 arias in some, exceptionally long, operas!). Narrative drive took a back seat to semi-formal rules on what type, how many and which arias were to appear in an opera.</p>

          <p>This formality of aria distribution was followed to an almost absurd degree during the 18th Century, a period when opera was dominated by the opera seria genre. Opera seria is a topic for another day, but suffice to say that for most of the 1700s almost all non-comic operas were opera seria. This orthodoxy was designed with rigid rules:</p>

          <ol>
              <li>Top singers got four or five arias, at least one in each act.</li>
              <li>All the subordinate singers got fewer but every singer must have at least one.</li>
              <li>No singer could sing two arias in a row</li>
              <li>No aria could be followed by another aria of the same type.</li>
              <li>Each aria ends with the singer leaving the stage</li>
          </ol>

          <p>If that all seems faintly ludicrous to you then we agree, and to top it off almost every aria was in one musical form: da capo (which we’ll explore below).</p>

          <p>This aria jigsaw had one major effect on most operas of this period: a composer's control over an opera was limited to individual arias. They had little concept of creating a coherent whole because there was no guarantee it would be performed anyway. This was an age when operas were chopped up and reassembled to suit differing tastes, arias from one opera could be shoved into another (with or without the composer's permission). A whole operatic genre developed called the pasticcio: new operas constructed from a collection of pre-existing arias from other operas!</p>

          <p>So with that short background into the history of operatic arias we can divide our exploration of arias into two chunks, arias defined by their musical structure and arias defined by their emotional content.</p>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>            

          <Header title="Musical Forms"/>

          <p>Arias can be defined by their musical form. Simply, this is boiling down an aria into how different parts interact. We label sections with letters, ABCD..., so a repeated section would be AA and two different sections following each other would be AB.</p>

          <SubHeader title="Strophic Form"/>

          <p>Strophic form (also chorus form) is the simplest of all the aria types: all the verses of the aria are sung to the same music. This can be understood as AAA, often a repeating musical bass over which the vocal part moved more freely. Strophic form was on the way out by the time opera was developing in the 1600s so you’ll hear very few arias of this type in opera houses today.</p>

          <VideoPlayer src="https://www.youtube.com/embed/LwndvoeCXos?autoplay=1" id="LwndvoeCXos" title="'Dal mio permesso' from Monteverdi's 'Orfeo'. Strophic variations over a repeating bass"/>

          <SubHeader title="Da capo"/>

          <p>The da capo aria dominated opera from the Baroque era through to the bel canto. Structured as ABA, the repeated A section was usually not written out with the composer writing “da capo” (literally “from the head”) at the end of the B section. The A section would generally be a self-contained song, the B section then contrasts entirely: written in a different key and usually faster. The aria would then return to section A only this time with the singer improvising variations and adding ornamentation (various vocal flourishes) to embellish the repeat with fresh excitement. The text was in a two parts, A and B, with the singer repeating A word for word.</p>

          <SubHeader title="Rondo"/>

          <p>You might also see this written as rondò, rondeau, rondeaux. A difficult term because it has meant different things at different times. In classical music rondo usually means an ABACA structure with each new section always returning to the first theme (you can think of this might like most pop music always returning to the chorus). The rondo form as it developed for opera in the 1760s and 70s was really ABAC with the opening ABA slower than the concluding C section. These were relatively uncommon and nearly always assigned to the principal parts and placed near the conclusion of the opera. This led to rondos being considered arias of special importance, which then led to composers labelling arias as rondos to appease singers’ egos even when the aria was not a rondo by any definition of the term!</p>

          <VideoPlayer src="https://www.youtube.com/embed/nIo89lVjnbw?autoplay=1" id="nIo89lVjnbw" title="'Deh per questo istante solo' from Mozart's 'La clemenza di Tito'"/>

          <SubHeader title="Cabaletta"/>

          <p>The cabaletta aria is shorthand for a two part form of aria that is more precisely cantabile-cabaletta form. The cantabile section is significantly slower, the cabaletta more virtuosic providing a strong distinction between the two sections. This aria form was wildly popular in the Italian bel canto era from around 1800 to roughly 1850, Bellini and Rossini stuffing their operas with them. It didn’t die out altogether however with Verdi’s <i>La Traviata</i> containing one of the most famous cabalettas of all, Violetta's "È strano! è strano" flowing into the phenomenal "Sempre libera".</p>

          <VideoPlayer src="https://www.youtube.com/embed/XoF8wcO-R8s?autoplay=1" id="XoF8wcO-R8s" title="'È strano! è strano' from Verdi's 'La Traviata'"/>

          <SubHeader title="Cavatina"/>

          <p>A short aria in one or two parts with no repeat (distinguishing it from da capo). You can find cavatinas in some Handel operas but mostly these are found in late-18th Century works (Mozart wrote a few), a period when the da capo aria fell somewhat out of favour, though French composers held on to the term well into the 19th Century. Otherwise rather vaguely defined, you’ll occasionally see it used in the place of cantabile (not helpfully!), and it later came to mean the opening aria for a principal singer in Italian opera.</p>

          <VideoPlayer src="https://www.youtube.com/embed/0V-JAe0ovKM?autoplay=1" id="0V-JAe0ovKM" title="'Salut demeure' from Gounod's Faust"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-105"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>            

          <Header title="Thematic Forms"/>

          <p>Perhaps because of the dominance of some aria forms (mostly da capo), arias in the late 17th through to early 19th centuries were further categorised by their content and style as much as their form. There are literally dozens of these terms tossed around but we’ve collected a few of the most common (and most delightful) below.</p>

          <SubHeader title="Aria di bravura"/>

          <p>A fast, virtuosic aria designed to show off the singer’s voice with an excess of flamboyant coloratura. Almost always given to the lead soprano role.</p>

          <VideoPlayer src="https://www.youtube.com/embed/sV1O9qb72YM?autoplay=1" id="sV1O9qb72YM" title="'Martern aller Arten' from Mozart's 'Die Entführung aus dem Serail'"/>

          <SubHeader title="Aria Parlante"/>

          <p>Also known as an infuriata or agitata, these are highly passionate arias. Sometimes on violent themes, highly syllabic (one syllable per note), filled with short notes and with little ornamentation. In opera seria these are usually driven by rage, in opera buffa more likely confusion and distress (rage is a bit heavy for comedy!)</p>

          <VideoPlayer src="https://www.youtube.com/embed/MtKqhnGYLY4?start=4&autoplay=1" id="MtKqhnGYLY4" title="'Non so più cosa son' from Mozart's 'Le nozze di Figaro'"/>

          <SubHeader title="Aria di baule"/>

          <p>Also known as a trunk aria, suitcase aria or insertion aria. These were arias sung by a singer during an opera for which that aria had not been composed. For much of opera’s history singers held enormous power and the aria di baule was a symptom of this. In order to best show off their voices, famous singers would have a collection of arias they sang best and would then insert into any opera as they fancied. This wasn’t entirely a free for all, singers would often have limits on these included in their contracts and most made at least some effort to pick an aria vaguely appropriate to the opera they were actually singing... The practice fell out of favour in the 19th Century (Verdi stipulated in his contracts that no insertions would be made) and with the rise in “authentic” performance the aria di baule is essentially taboo today.</p>

          <SubHeader title="Aria di sorbetto"/>

          <p>Otherwise know as a sherbert or ice cream aria. If you can believe it, this was an aria written for an unimportant character that would fall somewhere towards the end of the opera to give the opera house vendors one last chance to sell their wares to the audience.</p>

          <VideoPlayer src="https://www.youtube.com/embed/0MOT19BWSkI?autoplay=1" id="0MOT19BWSkI" title="'Il vecchiotto cerca moglie' from Rossini's 'Il Barbiere di Siviglia'"/>

          <Header title="Bibliography"/>

          <Bibliography content={bibliography}/>

        </MainBodySection>
      </Layout>
  )
}

export default AriasPage
